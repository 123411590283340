<template>
  <v-app>
    <app-header :app-name="'Tway'" />
    <v-main>
      <Loader :visible="isLoading" />
      <v-container fluid>
        <v-card width="600">
          <v-card-title class="headline">
            <div class="card-title text-center">
              <v-icon x-large color="green"> mdi-check-all </v-icon>
            </div>
          </v-card-title>
          <v-card-title class="headline font-weight-bold">
            <div class="voucher-title text-center">La suscripción ha sido pagada exitosamente</div>
          </v-card-title>

          <v-card-text> Detalles de la transacción </v-card-text>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Monto pagado</td>
                    <td class="font-weight-bold">$ {{ voucher.amount | numeral("0,0") }}</td>
                  </tr>
                  <tr>
                    <td>Fecha de pago</td>
                    <td class="font-weight-bold">
                      {{ voucher.date | formatDateTime }}
                    </td>
                  </tr>
                  <tr>
                    <td>ID de pago</td>
                    <td class="font-weight-bold">
                      {{ voucher.buyOrder }}
                    </td>
                  </tr>
                  <tr>
                    <td>Medio de pago</td>
                    <td class="font-weight-bold">
                      {{ voucher.paymentMethod }}
                    </td>
                  </tr>
                  <tr>
                    <td>Código de transacción</td>
                    <td class="font-weight-bold">
                      {{ voucher.tbkTransactionId }}
                    </td>
                  </tr>
                  <tr>
                    <td>Código de autorización</td>
                    <td class="font-weight-bold">
                      {{ voucher.authCode }}
                    </td>
                  </tr>
                  <tr>
                    <td>Tipo de tarjeta</td>
                    <td class="font-weight-bold">
                      {{ voucher.cardType }}
                    </td>
                  </tr>
                  <tr>
                    <td>Últimos 4 dígitos tarjeta</td>
                    <td class="font-weight-bold">
                      {{ voucher.last4CardDigits }}
                    </td>
                  </tr>
                  <tr>
                    <td>Tipo de cuotas</td>
                    <td class="font-weight-bold">
                      {{ "" }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-text> ¡Que tengas un buen día! </v-card-text>

          <v-card-actions class="text-center">
            <v-btn color="primary" :to="action.url">
              {{ action.text }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-main>
    <app-footer />
  </v-app>
</template>

<script type="text/javascript">
import AppFooter from "@/components/AppFooter";
import AppHeader from "@/components/AppHeader";
import Loader from "@/components/Loader";
import oneclickService from "@/services/billing/oneclickService";

export default {
  components: {
    AppFooter,
    AppHeader,
    Loader,
  },

  data() {
    return {
      isLoading: true,
      voucher: {
        id: "",
        userId: "",
        buyOrder: null,
        amount: null,
        date: null,
        paymentMethod: null,
        authCode: null,
        cardType: null,
        last4CardDigits: null,
        tbkTransactionId: null,
        inscription: null,
      },
      action: {
        url: "",
        text: "",
      },
    };
  },

  created() {
    this.voucher.id = this.$route.params.id;
    this.action.url = this.$route.query.redirectTo;
    this.action.text = this.$route.query.text;
    this.getVoucher();
  },

  methods: {
    getVoucher() {
      oneclickService
        .getTransaction(this.voucher.id)
        .then((transaction) => {
          this.voucher = transaction;
        })
        .catch((err) => this.$log.error("Unexpected error: ", err))
        .then(() => (this.isLoading = false));
    },
  },
};
</script>

<style scoped>
.card-title {
  min-width: 100%;
}
</style>
